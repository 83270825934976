<template>
  <div class="container-main-content login-by-staff">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-3">
          Đăng nhập cho nhân viên doanh nghiệp
        </h4>
        <p class="f14 text-second-color mb-4">
          Bạn có tài khoản chủ doanh nghiệp?<br />
          <a
            href="#"
            class="link-primary-color"
            @click.prevent="handleLoginByEnterprise"
            >Đăng nhập tại đây</a
          >
        </p>
        <div class="content-inner__body-form">
          <ValidationObserver ref="formLogin">
            <form @submit.prevent="handleLogin">
              <ValidationProvider
                name="Địa chỉ đăng nhập doanh nghiệp"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="aliasMerchant"
                    class="form-control form-control-with-text"
                    id="floatingPathEnterprise"
                    placeholder="name@example.com"
                  />
                  <span class="form-input-plus">{{ APP_DOMAIN }}/s/</span>
                  <label for="floatingPathEnterprise">
                    Địa chỉ đăng nhập doanh nghiệp
                  </label>
                </div>
                <p
                  v-if="errors[0]"
                  class="text-danger f12 mb-4"
                  style="margin-top: -18px"
                >
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
        <button type="button" class="btn btn-primary mb-4" @click="handleLogin">
          TIẾP TỤC
        </button>
        <p>
          <a
            href="#"
            class="link-primary-color f14"
            @click.prevent="handleForgetPassword"
            >Quên mật khẩu?</a
          >
        </p>
        <p class="content-inner__body-start f14">
          Chưa có tài khoản?
          <a href="#" class="link-primary-color" @click.prevent="handleSignUp">
            Bắt đầu ngay
          </a>
        </p>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { APP_DOMAIN } from "../../.env";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      APP_DOMAIN,
      aliasMerchant: "",
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
    };
  },
  async created() {
    await this.getAliasMerchant();
  },
  methods: {
    handleLoginByEnterprise() {
      this.$router.push({ path: `/login-by-enterprise${this.currentQueries}` });
    },
    handleLogin() {
      this.$refs.formLogin.validate().then(async (success) => {
        if (success) {
          this.$router.push({
            path: `/login-by-staff/${this.aliasMerchant}${this.currentQueries}`,
          });
        }
      });
    },
    handleForgetPassword() {
      this.$router.push({ path: `/forget-password${this.currentQueries}` });
    },
    handleSignUp() {
      this.$router.push({ path: `/signup${this.currentQueries}` });
    },
    async getAliasMerchant() {
      const aliasMerchant = this.$route.query.aliasMerchant;
      this.aliasMerchant = aliasMerchant != undefined ? aliasMerchant : "";
    },
  },
};
</script>
